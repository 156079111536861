import Link from "next/link";

function ReachOut (){
    return (
      <>
        <div className="pb-8">
            {/* <Link href={"/"}>Aryan</Link> */}
            {/* <Image src={LogoImage} alt="Aryan Chaurasia" width={200} height={100} quality={100} /> */}
            <h2>Reach Out</h2>
            <div>
              <Link className={`hover:underline underline-offset-4 block`} href="mailto:me@aryn.tech"><i className="ri-mail-send-line mr-2"/>Email</Link>
              <Link className={`hover:underline underline-offset-4 block`} href="https://twitter.com/thehungrybird_" target="_blank"><i className="ri-twitter-line mr-2"/>Twitter</Link>
              <Link className={`hover:underline underline-offset-4 block`} href="https://instagram.com/the.hungry.bird" target="_blank"><i className="ri-instagram-line mr-2"/>Instagram</Link>
              <Link className={`hover:underline underline-offset-4 block`} href="https://www.linkedin.com/in/thehungrybird/" target="_blank"><i className="ri-linkedin-line mr-2"/>LinkedIn</Link>
            </div>
        </div>
      </>
    );
}

export default ReachOut;