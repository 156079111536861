import Head from "next/head";
import dynamic from 'next/dynamic'
import { Suspense } from "react";

// Components
import ReachOut from "../components/reachout";

// Data
import skillsData from "/src/json/skills.json";
import workData from "/src/json/work.json";
import serviceData from "/src/json/services.json";
// import projectData from "/src/json/projects.json";
import { isDesktop } from "react-device-detect";


// Blob Fold
const DynamicBlob = dynamic(() => import("../components/blobfold"),{
  loading: () => 'Loading...',
});

// Services
const DynamicServices = dynamic(() => import("../components/services"), {
  loading: () => 'Loading...',
});

// Skills
const DynamicSkills = dynamic(() => import("../components/skills"),{
  loading: () => 'Loading...',
});

// Work
const DynamicWork = dynamic(() => import("../components/portfolio"),{
  loading: () => 'Loading...',
});

export default function Home(env_var) {
  return (
    <>
      <Head>
        <title>{env_var.site_name}</title>
      </Head>
      {/* Lesss Go!!! */}
      {/* First Fold */}

      <DynamicBlob />

      {/* Skills */}
      <Suspense fallback={<p>Loading Skills</p>}>
        <h2>Skills</h2>
        <DynamicSkills data={skillsData} />  
      </Suspense>

      {/* Services */}
      <h2>Services</h2>
      <Suspense>
        <DynamicServices data={serviceData} />  
      </Suspense>

      {/* Work */}
      <h2>Portfolio</h2>
      <Suspense>
        <DynamicWork data={workData} load={false} workNum={6}/>
      </Suspense>

      {/* Reachout */}
      <ReachOut />
    </>
  )
}

export async function getStaticProps() {
  const env_var = {
    site_name: process.env.SITE_NAME
  }
  return {props: env_var};
}